import React, {useEffect, useRef} from 'react';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {DynamicStep, DynamicStepComponentProps} from '../../StepsManager/Components/DynamicStep';
import {StepHeader} from '../../StepsManager/Components/StepHeader';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {StepImplementationProps, StepState} from '../../../../../types/app.types';
import {PlaceOrder} from './Components/PlaceOrder/PlaceOrder';
import {usePaymentsApi} from '../../../WithPaymentsApi/WithPaymentsApi';
import {useBillingData, withBillingData} from '../PaymentStep/WithBillingData/WithBillingData';
import {useMemberDetailsData, withMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {PaymentWidgetWrapper} from '../PaymentStep/PaymentWidgetWrapper';
import {BillingDetails} from '../PaymentStep/BillingDetails/BillingDetails';
import {CheckoutModel} from '../../../../../domain/models/Checkout.model';
import {StatesButtonStates} from 'wix-ui-tpa/cssVars';
import {UsePlaceOrderButtonState} from './UsePlaceOrderButtonState';

export enum PaymentAndPlaceOrderDataHook {
  root = 'PaymentAndPlaceOrderDataHook.root',
  header = 'PaymentAndPlaceOrderDataHook.header',
  open = 'PaymentAndPlaceOrderDataHook.open',
}

const InternalPaymentAndPlaceOrderStep = ({index}: DynamicStepComponentProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const localeKeys = useLocaleKeys();
  const {paymentsApi} = usePaymentsApi();

  const {
    checkoutStore: {isPlaceOrderButtonDisabled},
    formsStore: {areFormsLoaded},
    stepsManagerStore: {stepsList},
    memberStore: {isMember},
  } = useControllerProps();

  const {initForm, contactCountry} = useBillingData();

  const {resetMemberDetailsState} = useMemberDetailsData();

  const {setPlaceOrderButtonState, setShouldPlaceOrderButtonBeDisabled} = UsePlaceOrderButtonState();

  const stepState = stepsList[index].state;

  useEffect(
    () => {
      if (stepState === StepState.OPEN) {
        void paymentsApi?.expand();
        setPlaceOrderButtonState(StatesButtonStates.IDLE);
        setShouldPlaceOrderButtonBeDisabled(!areFormsLoaded || isPlaceOrderButtonDisabled);
        initForm();
        if (isMember) {
          resetMemberDetailsState();
        }
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [stepState]
  );

  return (
    <>
      <StepHeader
        index={index}
        dataHook={PaymentAndPlaceOrderDataHook.header}
        label={localeKeys.checkout.payment()}
        ref={headerRef}
      />
      <PaymentWidgetWrapper stepState={stepState} country={contactCountry} />
      {stepState === StepState.OPEN && (
        <div data-hook={PaymentAndPlaceOrderDataHook.open}>
          <BillingDetails />
          <PlaceOrder />
        </div>
      )}
    </>
  );
};

const WrappedInternalPaymentAndPlaceOrderStep = withMemberDetailsData(
  withBillingData(InternalPaymentAndPlaceOrderStep),
  (checkout: CheckoutModel) => checkout.billingInfo,
  'validateBillingAddress'
);

export const PaymentAndPlaceOrderStep = ({index}: StepImplementationProps) => {
  return (
    <DynamicStep
      index={index!}
      dataHook={PaymentAndPlaceOrderDataHook.root}
      component={WrappedInternalPaymentAndPlaceOrderStep}
    />
  );
};
