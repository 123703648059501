import {useEffect, useState} from 'react';
import {StatesButtonStates} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {useMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {ADD_NEW_ADDRESS_ID} from '../../../constants';
import {StepId} from '../../../../../types/app.types';

export function UsePlaceOrderButtonState() {
  const {
    checkoutStore: {updateCheckoutError, isPlaceOrderButtonDisabled, placeOrderError, isFastFlow},
    formsStore: {areFormsLoaded},
    stepsManagerStore: {activeStep},
  } = useControllerProps();

  const {editMode, areMemberDetailsValid, selectedAddressesServiceId} = useMemberDetailsData();

  const [placeOrderButtonState, setPlaceOrderButtonState] = useState(StatesButtonStates.IDLE);

  const [shouldPlaceOrderButtonBeDisabled, setShouldPlaceOrderButtonBeDisabled] = useState(
    (!isFastFlow && activeStep.stepId === StepId.paymentAndPlaceOrder && !areFormsLoaded) || isPlaceOrderButtonDisabled
  );

  useEffect(() => {
    if (isFastFlow) {
      setShouldPlaceOrderButtonBeDisabled(isPlaceOrderButtonDisabled);
    }
  }, [isPlaceOrderButtonDisabled, isFastFlow]);

  useEffect(() => {
    if (updateCheckoutError || placeOrderError) {
      setPlaceOrderButtonState(StatesButtonStates.IDLE);
      setShouldPlaceOrderButtonBeDisabled(isPlaceOrderButtonDisabled);
    }
  }, [updateCheckoutError, placeOrderError, isPlaceOrderButtonDisabled]);

  useEffect(() => {
    if (!isFastFlow && activeStep.stepId === StepId.paymentAndPlaceOrder) {
      const disablePlaceOrderButton =
        !areFormsLoaded || (!areMemberDetailsValid && !editMode && selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID);
      setShouldPlaceOrderButtonBeDisabled(disablePlaceOrderButton || isPlaceOrderButtonDisabled);
    }
  }, [
    areFormsLoaded,
    areMemberDetailsValid,
    editMode,
    selectedAddressesServiceId,
    activeStep,
    isFastFlow,
    isPlaceOrderButtonDisabled,
  ]);

  return {
    placeOrderButtonState,
    shouldPlaceOrderButtonBeDisabled,
    setPlaceOrderButtonState,
    setShouldPlaceOrderButtonBeDisabled,
  };
}
